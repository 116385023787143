import { Box, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import theme, {
  Form,
  InputText,
  InputPhone,
  InputEmail,
  InputCnpj,
  Button,
  Flex,
  IFormData,
  Alert,
  Link,
} from '@memed/epiderme'
import InputPassword from '../../components/Inputs/InputPassword'
import { useState } from 'react'

import { Header } from '../../components/Header'
import { KeycloakContextProps } from '../../keycloak/types/context.type'
import { KcContextBase } from 'keycloakify'

import { SubmitForm } from '../../utils/SubmitForm'
import { FlexRegister, Tab } from './styles'
import { useFetchIndustry } from '../../hooks/useFetchIndustry'
import { unmaskCnpj } from '../../utils/unmaskCnpj'
import { useKeycloackContext } from '../../hooks/useKeycloakContext'
import { SearchIndustries } from '../../components/SearchIndustries'
import { IIndustry } from '../../components/ListIndustries/Industry.interface'
import { Footer } from '../../components/Footer'
import { Tooltip } from '../../components/Tooltip'

const STEP_ONE = 1
const STEP_TWO = 2
const YOUR_DATA_TAB = 0
const INDUSTRY_DATA_TAB = 1

export const Register = ({ kcContext }: KeycloakContextProps) => {

  const { industries } = useFetchIndustry()

  const { context: { url, message } } = useKeycloackContext<KcContextBase.Register>(kcContext)
  const [hasServerError, setHasServerError] = useState(() => message?.type === 'error')

  const [showValidation, setShowValidation] = useState(false)

  const [formData, setFormData] = useState({
    step: 1,
    pipdrive_id: '',
    telephone: '',
    email: '',
    password: '',
    confirm_password: '',
    customer: '',
    selectedIndustries: [] as IIndustry[],
    cnpj: '',
    corporate_name: '',
  })

  const addIndustry = (industry: IIndustry) => {
    // if (formData.selectedIndustries.length === 3) {
    //   setShowValidation(true)
    // }
    const exists = !!formData.selectedIndustries.find(({ id }) => id === industry.id)
    if (!exists) {
      setFormData((prevState) => ({
        ...prevState,
        selectedIndustries: [...prevState.selectedIndustries, industry]
      }))
    }
  }

  const removeIndustry = (industry: IIndustry) => {
    setShowValidation(false)
    const industriesWithoutRemoved = formData.selectedIndustries.filter(i => i.id !== industry.id)
    setFormData((prevState) => ({
      ...prevState,
      selectedIndustries: [...industriesWithoutRemoved]
    }))
  }

  // const isInValidSelectedIndustries = formData.selectedIndustries.length > 3

  const confim = ({ isValid }: IFormData) => {
    
    if (!isValid) {
      setShowValidation(true)
      return
    }

    const dataToSubmit = {
      'pipdrive-id': formData.pipdrive_id,
      telephone: formData.telephone,
      email: formData.email,
      password: formData.password,
      'password-confirm': formData.confirm_password,
      customer: formData.customer,
      'industries': formData.selectedIndustries.map(industry => JSON.stringify(industry)).join('##'),
      'corporate-name': formData.corporate_name,
      cnpj: unmaskCnpj(formData.cnpj)
    }

    const form = new SubmitForm({
      action: url.registrationAction,
      method: 'POST'
    })

    form.execute(dataToSubmit)

  }

  const nextStep = (e: IFormData) => {
    if (!e.isValid) {
      setShowValidation(true)
      return
    }

    setFormData((prevState) => ({
      ...prevState,
      step: prevState.step + 1,
    }))
  }

  const prevStep = () => {
    setFormData((prevState) => ({
      ...prevState,
      step: prevState.step - 1,
    }))
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {

    setHasServerError(false)
    
    setShowValidation(false)

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const isPasswordSame = (confirmPassword: string) =>
    formData.password === confirmPassword

  const currentStep = () =>
    formData.step === STEP_ONE ? YOUR_DATA_TAB : INDUSTRY_DATA_TAB

  return (
    <FlexRegister>
      <Header.Container mb="1.5rem">
        <Header.Title>Crie sua conta</Header.Title>
        <Header.Subtitle>
          Para reservar sua posição de Destaque no Catálogo Memed, precisamos
          que você insira os dados abaixo:
        </Header.Subtitle>
      </Header.Container>

      {hasServerError ? 
        (
          <Alert
            padding={theme.space['1.5']}
            marginBottom="1.5rem"
            description={message?.summary || 'Erro inesperado, por favor, contate um administrador'}
            status='error'
          />
        ) : null
      }

      <Tabs index={currentStep()}>
        <TabList gap=".5rem" borderBottom="none">
          <Tab isDisabled={formData.step === STEP_TWO}>Seus dados</Tab>
          <Tab isDisabled={formData.step === STEP_ONE}>Dados da indústria</Tab>
        </TabList>

        <TabPanels>
          {/* Your Data */}
          <TabPanel padding={0} paddingTop={"24px"}>
            <Form onSubmit={nextStep}>
              <Flex flexFlow="row wrap" gap="1rem">
                <InputText
                  label="ID do pipdrive*"
                  name="pipdrive_id"
                  isRequired
                  placeholder="Id do Pipedrive"
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="14rem"
                  showValidation={showValidation}
                  onChange={handleChange}
                  value={formData.pipdrive_id}
                />

                <InputPhone
                  label="Telefone"
                  name="telephone"
                  placeholder="(11) 99999-9999"
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="9.5rem"
                  showValidation={showValidation}
                  onChange={handleChange}
                  value={formData.telephone}
                />

                <InputEmail
                  isRequired
                  label="Seu e-mail Memed*"
                  name="email"
                  placeholder="Endereço de e-mail"
                  showValidation={showValidation}
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="100%"
                  onChange={handleChange}
                  value={formData.email}
                />

                <InputPassword
                  isRequired
                  label="Senha*"
                  name="password"
                  placeholder="Senha"
                  showValidation={showValidation}
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="13rem"
                  onChange={handleChange}
                  value={formData.password}
                />

                <InputPassword
                  isRequired
                  label="Confirme sua senha*"
                  name="confirm_password"
                  placeholder="Confirme sua senha"
                  showValidation={showValidation}
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="13rem"
                  onChange={handleChange}
                  value={formData.confirm_password}
                  errorText="As senhas não coincidem"
                  externalCustomValidation={[isPasswordSame]}
                />

                <Button
                  variant="solid"
                  size="sm"
                  colorScheme="primary"
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="100%"
                  type="submit"
                >
                  Continuar
                </Button>

                <Flex
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Footer.Container>
                    <Footer.Label>
                        Já possui uma conta?
                        <Link 
                          href={url.loginUrl} 
                          type="text-link-sm" ml="0.25rem">
                          Faça seu login
                        </Link>
                      </Footer.Label>
                  </Footer.Container>
                </Flex>
              </Flex>
            </Form>
          </TabPanel>

          {/* Industry Data */}
          <TabPanel padding={0} paddingTop={"24px"}>
            <Form onSubmit={confim}>
              <Flex flexFlow="row wrap" gap="1rem">
                <InputText
                  isRequired
                  label="Nome do cliente contratante*"
                  name="customer"
                  placeholder="Nome e sobrenome"
                  showValidation={showValidation}
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="100%"
                  onChange={handleChange}
                  value={formData.customer}
                />

                <InputCnpj
                  iconRight={
                    <Tooltip 
                      labelContent='Este deve ser o CNPJ que irá para o contrato e nota fiscal da reserva.'
                    />
                  }
                  isRequired
                  label="CNPJ para Contrato*"
                  name="cnpj"
                  placeholder="00.000.000/0001-00"
                  showValidation={showValidation}
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="10rem"
                  onChange={handleChange}
                  value={formData.cnpj}
                />

                <InputText
                  iconRight={
                    <Tooltip 
                      labelContent='Esta deve ser a Razão Social que irá para o contrato e nota fiscal da reserva.'
                    />
                  }
                  isRequired
                  label="Razão Social*"
                  name="corporate_name"
                  placeholder="Razão social"
                  showValidation={showValidation}
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="11rem"
                  onChange={handleChange}
                  value={formData.corporate_name}
                />

                <Box flexGrow={1} flexShrink={1} flexBasis="100%">
                  <SearchIndustries
                    label="Indústria(s)*"
                    placeholder="Busque indústria(s) do mesmo grupo"
                    add={addIndustry}
                    remove={removeIndustry}
                    industries={industries}
                    selectedIndustries={formData.selectedIndustries}
                  />
                </Box>

                {/* Actions  */}
                <Button
                  variant="ghost"
                  size="sm"
                  colorScheme="primary"
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="13rem"
                  type="button"
                  onClick={() => prevStep()}
                >
                  Voltar
                </Button>
                <Button
                  variant="solid"
                  size="sm"
                  colorScheme="primary"
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="13rem"
                  type="submit"
                >
                  Acessar ferramenta
                </Button>
              </Flex>
            </Form>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FlexRegister>
  )
}
