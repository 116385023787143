import { Divider as ChakraDivider } from '@chakra-ui/react'
import { Text, Flex } from '@memed/epiderme'
import { PropsWithChildren } from 'react'


interface ContainerProps extends PropsWithChildren {}
const Container = ({ children }: ContainerProps) => (
  <>{children}</>
)

const Divider = ({ children }: PropsWithChildren) => (
  <Flex mt="0.5rem" mb="0.5rem" w="100%" align="center">
    <ChakraDivider />
    <Text padding="0.5rem" color="neutral.400">
      {children}
    </Text>
    <ChakraDivider />
  </Flex>
)

const Label = ({ children }: PropsWithChildren) => (
  <Text
    as="p"
    fontWeight="bold"
    lineHeight="base"
    fontSize="sm"
    alignSelf="center"
    color="neutral.500"
  >
    {<>{children}</>}
  </Text>
)

export const Footer = {
  Container,
  Divider,
  Label
}
