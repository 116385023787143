export const errorMessages = {
  MIN_LENGTH: 'A senha ter o tamanho acima de 8 caracteres',
  UPPERCASE_REQUIRED: 'A senha deve conter letra maiúscula',
  SPECIAL_CARACTER_REQUIRED: 'A senha deve conter pelo menos um caractere especial',
};

export function hasMinLength(password: string) {
  return password.length >= 8;
}

export function hasUppercase(password: string) {
  const uppercase = /[A-Z]/;

  return new RegExp(uppercase).test(password);
}

export function hasSpecialCaracter(password: string) {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

  return new RegExp(specialChars).test(password);
}
