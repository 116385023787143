import { createIcon } from '@chakra-ui/icons'

export const Info = createIcon({
  displayName: 'Info',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd" 
      clipRule="evenodd"
      fill='currentColor'
      d="M12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75ZM2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75C6.61479 21.75 2.25 17.3852 2.25 12ZM10.75 8.25C10.75 7.69772 11.1977 7.25 11.75 7.25C12.3023 7.25 12.75 7.69772 12.75 8.25C12.75 8.80228 12.3023 9.25 11.75 9.25C11.1977 9.25 10.75 8.80228 10.75 8.25ZM10.25 12C10.25 11.5858 10.5858 11.25 11 11.25H12C12.4142 11.25 12.75 11.5858 12.75 12V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V12.75H11C10.5858 12.75 10.25 12.4142 10.25 12Z"
    />
  ),
})