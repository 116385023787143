import { Tag } from '@chakra-ui/react';
import theme, { Box, InputText } from '@memed/epiderme';
import { useCallback, useEffect, useState } from 'react';
import { Close } from '../Icons/Close';
import { SearchLoupe } from '../Icons/SearchLoupe';
import { ListIndustries } from '../ListIndustries';
import { IIndustry } from '../ListIndustries/Industry.interface';

type SearchIndustriesProps = {
  industries: IIndustry[];
  selectedIndustries: IIndustry[];
  remove: (industry: IIndustry) => void;
  add: (industry: IIndustry) => void;
  label: string;
  placeholder: string;
  // showValidation?: boolean;
}

export const SearchIndustries = ({ 
  label, 
  placeholder, 
  industries, 
  selectedIndustries,
  add, 
  remove,
  // showValidation,
}: SearchIndustriesProps) => {

  const [inputSearchTerm, setInputSearchTerm] = useState('');
  const [localIndustries, setLocalIndustries] = useState<IIndustry[]>([]);
  const [showResults, setShowResults] = useState(false);
  
  const getBySearchTerm = useCallback((searchTerm: string) => {
    setLocalIndustries(
      industries.filter(({ name }) => {
        return name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      })
    )
    setShowResults(true);
  }, [industries])

  const addIndustry = (industry: IIndustry) => {
    setShowResults(false)
    setInputSearchTerm('');
    add(industry)
  };

  useEffect(() => {
    if (!inputSearchTerm) {
      setShowResults(false);
      setLocalIndustries(industries);
    } else {
      getBySearchTerm(inputSearchTerm);
    }
  }, [industries, inputSearchTerm, getBySearchTerm]);

  return (
    <>
      <Box position="relative">
        <InputText
          iconLeft={<SearchLoupe color="neutral.600" boxSize={theme.sizes[6]} />}
          label={label}
          placeholder={placeholder}
          onChange={(e) => setInputSearchTerm(e.target.value)}
          value={inputSearchTerm}
          isRequired={!selectedIndustries.length}
          // showValidation={showValidation}
          // isInvalid={showValidation}
          // errorText="Só é possível selecionar 3 indústrias"
        />
        <ListIndustries
          label='Nenhuma indústria encontrada'
          industries={localIndustries}
          showResults={showResults}
          addIndustry={addIndustry}
        />
      </Box>

      { selectedIndustries.length
        ? 
          <Box paddingTop={2} paddingBottom={3} display="flex" gap="3" flexWrap="wrap">
            { selectedIndustries.map((industry: IIndustry) => (
              <Tag
                bgColor={theme.colors.secondary[200]}
                color={theme.colors.secondary[900]}
                fontWeight={theme.fontWeights.medium}
                size="md"
                borderRadius={theme.radii.full}
                variant="light"
                display="inline-flex"
                onClick={() => { remove(industry); }}
                cursor="pointer"
                key={industry.id}
              >
                {industry.name}
                <Close color="secondary.700" boxSize={theme.sizes[4]}/>
              </Tag>
            ))}
          </Box>

        :  null
      }

    </>
  );
};
