import { createIcon } from '@chakra-ui/icons'

export const EyeShow = createIcon({
  displayName: 'EyeShow',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd" 
      clipRule="evenodd"
      fill='currentColor'
      d='M3.11799 12.467L2.45793 12.8231L2.45822 12.8237L3.11799 12.467ZM3.11799 11.532L2.45827 11.1753L2.45793 11.1759L3.11799 11.532ZM20.882 11.533L21.5421 11.1769L21.5418 11.1763L20.882 11.533ZM20.882 12.468L21.5417 12.8247L21.5421 12.8241L20.882 12.468ZM3.77806 12.1109C3.74097 12.0422 3.74097 11.9568 3.77806 11.8881L2.45793 11.1759C2.18101 11.6892 2.18101 12.3098 2.45793 12.8231L3.77806 12.1109ZM3.77772 11.8887C4.67816 10.2235 5.95894 8.67372 7.41648 7.54732C8.87814 6.41774 10.4684 5.75 12 5.75V4.25C10.0366 4.25 8.13185 5.09876 6.49925 6.36043C4.86255 7.62528 3.44982 9.34151 2.45827 11.1753L3.77772 11.8887ZM12 5.75C13.5316 5.75 15.1218 6.41774 16.5835 7.54742C18.041 8.67393 19.3218 10.2239 20.2222 11.8897L21.5418 11.1763C20.5502 9.34208 19.1375 7.62557 17.5008 6.36058C15.8682 5.09876 13.9634 4.25 12 4.25V5.75ZM20.2219 11.8891C20.259 11.9578 20.259 12.0432 20.2219 12.1119L21.5421 12.8241C21.819 12.3108 21.819 11.6902 21.5421 11.1769L20.2219 11.8891ZM20.2223 12.1113C19.3218 13.7765 18.0411 15.3263 16.5835 16.4527C15.1218 17.5823 13.5316 18.25 12 18.25V19.75C13.9634 19.75 15.8681 18.9012 17.5007 17.6396C19.1374 16.3747 20.5502 14.6585 21.5417 12.8247L20.2223 12.1113ZM12 18.25C10.4684 18.25 8.87816 17.5823 7.41651 16.4526C5.95898 15.3261 4.67821 13.7761 3.77777 12.1103L2.45822 12.8237C3.44978 14.6579 4.86251 16.3744 6.49922 17.6394C8.13183 18.9012 10.0366 19.75 12 19.75V18.25ZM13.591 10.409C14.4696 11.2877 14.4696 12.7123 13.591 13.591L14.6516 14.6517C16.1161 13.1872 16.1161 10.8128 14.6516 9.34835L13.591 10.409ZM13.591 13.591C12.7123 14.4697 11.2877 14.4697 10.409 13.591L9.34832 14.6517C10.8128 16.1161 13.1872 16.1161 14.6516 14.6517L13.591 13.591ZM10.409 13.591C9.53031 12.7123 9.53031 11.2877 10.409 10.409L9.34832 9.34835C7.88386 10.8128 7.88386 13.1872 9.34832 14.6517L10.409 13.591ZM10.409 10.409C11.2877 9.53033 12.7123 9.53033 13.591 10.409L14.6516 9.34835C13.1872 7.88389 10.8128 7.88389 9.34832 9.34835L10.409 10.409Z'
    />
  ),
})