// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { getKcContext } from "keycloakify";

export const { kcContext } = getKcContext(
    /* @INFO O mockedContext permite simular uma aplicação conectada ao Keycloak.
        Dessa forma, é possível usar o modo de desenvolvimento (yarn start) com hotreload

        Todas as propriedades do contexto do Keycloak podem ser alteradas por aqui.
        No exemplo abaixo, a tela de login será simulada:
    */
    {
        mockPageId: "login.ftl",
        // mockPageId: "register-user-profile.ftl",
        //  mockData: [
        //     {
        //         pageId: "register.ftl",
        //         realm: {
        //             registrationAllowed: true
        //         },
        //         social: {
        //             displayInfo: true,
        //             providers: [
        //                 {
        //                     loginUrl: "string",
        //                     alias: "string",
        //                     providerId: "apple",
        //                     displayName: "Entrar com a Apple",
        //                 },
        //                 {
        //                     loginUrl: "string",
        //                     alias: "string",
        //                     providerId: "google",
        //                     displayName: "Entrar com o Google",
        //                 }
                        
        //             ]
        //         },
        //         message: {
        //             summary: "Email ou senha inválido.",
        //             type: "error"
        //         },
        //         url: {
        //             loginAction: "http://localhost:8080"
        //         }
        //     }
        //  ]
    }
);
