import { Flex } from '@chakra-ui/react'
import theme, { Box } from '@memed/epiderme'
import styled from '@emotion/styled'

export const FlexLogin = styled(Flex)`
  background-color: #fff;
  max-width: 30rem;
  padding: ${theme.space[6]};
  border-radius: ${theme.radii.xl};
  box-shadow: ${theme.shadows.lg};
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-left: 0;
  height: 100vh;

  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin-left: 20rem;
  }

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    height: fit-content;
  }
`

export const Wrapper = styled(Box)`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`