/* eslint-disable react/no-children-prop */

import { InputGroup, InputLeftElement, InputRightElement, Spinner } from '@chakra-ui/react';
import { Icon } from '@memed/epiderme';
import { IInputIconBase } from './InputIconBase.interface';

const InputIconBase = ({ ...props }: IInputIconBase) => {
  const iconLeft = typeof props.iconLeft === 'string' && props.iconColor ? <Icon icon={props.iconLeft} color={props.iconColor()} /> : props.iconLeft;
  const iconRight = typeof props.iconRight === 'string' && props.iconColor ? <Icon icon={props.iconRight} color={props.iconColor()} /> : props.iconRight;

  return (
    <InputGroup data-testid="input-group">
      {iconLeft && <InputLeftElement children={iconLeft} />}

      {props.children && props.children}

      {!props.isLoading && iconRight && <InputRightElement children={iconRight} />}

      {props.isLoading && <InputRightElement children={<Spinner size="md" color="neutral.300" />} />}
    </InputGroup>
  );
};

export default InputIconBase;
