import { KcApp as Default } from 'keycloakify/lib/components/KcApp'
import { defaultKcProps } from 'keycloakify'
import { keycloakContext } from '../../keycloak/context/keyCloackContext'
import { KeycloakContextProps } from '../../keycloak/types/context.type'

import { Login } from '../../pages/Login/Login'
import { Register } from '../../pages/Register/Register'

export const AppRoutes = () => {

  const context: KeycloakContextProps = { kcContext: keycloakContext, ...defaultKcProps }

  switch (keycloakContext.pageId) {
    case 'login.ftl': return (
      <Login {...context} />
    )
    case 'register-user-profile.ftl': return (
      <Register {...context} />
    )
    default: return <Default {...context} />
  }
}