
import { ChakraProvider } from '@chakra-ui/react'
import theme from '@memed/epiderme'

import { AppRoutes } from './routes/AppRoutes'
import { AppContainer } from './components/AppContainer'

export const App = () => (
  <ChakraProvider theme={theme}>
    <AppContainer>
      <AppRoutes />
    </AppContainer>
  </ChakraProvider>
)
