/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import theme, { Button, InputEmail, Link, Form, IFormData, Alert } from '@memed/epiderme'
import InputPassword from '../../components/Inputs/InputPassword'
import { KcContextBase } from 'keycloakify'
import { KeycloakContextProps } from '../../keycloak/types/context.type'

import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { FlexLogin, Wrapper } from './styles'
import { SubmitForm } from '../../utils/SubmitForm'
import { useKeycloackContext } from '../../hooks/useKeycloakContext'

export const Login = ({ kcContext }: KeycloakContextProps) => {

  const [showValidation, setShowValidation] = useState(false)

  const { context: { url, message } } = useKeycloackContext<KcContextBase.Login>(kcContext)
  const [hasServerError, setHasServerError ] = useState(() => message?.type === 'error')

  const onSubmit = ({ isValid, data }: IFormData) => {
    if (!isValid) {
      setShowValidation(true)
      return
    }

    const dataToSubmit = {
      username: data.username,
      password: data.password
    }

    const form = new SubmitForm({
      action: url.loginAction,
      method: 'POST'
    })
    
    form.execute(dataToSubmit)
   
  }

  return (
    <FlexLogin>
      <Header.Container>
        <Header.Title>Entrar no Destaque no Catálogo Memed</Header.Title>
        <Header.Subtitle>
          Insira seus dados cadastrais para acessar o Destaque no Catálogo
          Memed.
        </Header.Subtitle>
      </Header.Container>

      {hasServerError ? 
        (
          <Alert
            padding={theme.space['1.5']}
            description={message?.summary || 'Erro inesperado, por favor, contate um administrador'}
            status='error'
          />
        ) : null
      }
      
      <Form onSubmit={(e) => onSubmit(e)}>
        <Wrapper>
          <InputEmail
            isRequired
            name="username"
            label="E-mail"
            placeholder="Endereço de e-mail"
            size="sm"
            onChange={() => {
              setHasServerError(false)
              setShowValidation(false)
            }}
            showValidation={showValidation}
          />
          <InputPassword
            isRequired
            name="password"
            label="Senha"
            placeholder="Senha"
            size="sm"
            onChange={() => {
              setHasServerError(false)
              setShowValidation(false)
            }}
            showValidation={showValidation}
            externalCustomValidation={[(password: string) => password.length > 0]}
          />
          <Button variant="solid" size="sm" colorScheme="primary" type="submit">
            Entrar
          </Button>
        </Wrapper>
      </Form>

      {/* <Flex marginTop="1.5rem" flexDirection="column" justifyContent="center" alignItems="start"> */}
        <Footer.Container>
          {/* <Link 
            alignSelf="center" 
            textDecoration="none" 
            mt="1.2rem" 
            type="text-link-sm"
            href={url.loginResetCredentialsUrl}
          >
            Esqueci minha senha
          </Link>
          <Footer.Divider>Ou</Footer.Divider> */}
          <Footer.Label>
            Novo usuário?
            <Link 
              href={url.registrationUrl} 
              type="text-link-sm" ml="0.25rem">
              Crie sua conta
            </Link>
          </Footer.Label>
        </Footer.Container>
      {/* </Flex> */}
    </FlexLogin>
  )
}
