import axios, { AxiosError, AxiosResponse } from 'axios'

const BASE_URL = process.env.REACT_APP_API_AD_DRUGS

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => Promise.reject(error.response?.data),
);

export {
  axiosInstance as api
}