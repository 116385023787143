import { Heading, Text, Box } from '@memed/epiderme'
import { BoxProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

const Container = ({ children, ...rest }: BoxProps) => (<Box {...rest}>{children}</Box>)

const Title = ({ children }: PropsWithChildren) => (
  <Heading
    as="h1"
    type="title-sm"
    fontWeight="bold"
    color="neutral.700"
    lineHeight="shorter"
  >
    {children}
  </Heading>
)

const Subtitle = ({ children }: PropsWithChildren) => (
  <Text
    as="p"
    fontWeight="medium"
    lineHeight="base"
    color="neutral.500"
  >
    {children}
  </Text>
)

export const Header = {
  Container,
  Title,
  Subtitle
}