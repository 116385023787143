import { Box } from '@memed/epiderme';
import { ReactNode } from 'react';

export const DropdownList = ({ children, showResults } : { children: ReactNode, showResults: boolean }) => (
  <Box
    position="absolute"
    top={74}
    left={0}
    width="100%"
    maxH="8rem"
    background="white"
    paddingBottom={2}
    zIndex={1}
    boxShadow="md"
    border="1px"
    borderColor="gray.200"
    overflowY="auto"
    borderRadius="lg"
    display={showResults ? 'block' : 'none'}
  >
    {children}
  </Box>
);

export const ItemListIndustries = ({ children, onClick } : { children: ReactNode, onClick: () => void }) => (
  <Box
    as="button"
    type='button'
    paddingY={2}
    paddingX={3}
    cursor="pointer"
    display="flex"
    gap={3}
    marginTop={2}
    width="100%"
    _hover={{
      background: 'primary.50',
    }}
    onClick={onClick}
  >
    {children}
  </Box>
);
