import { Text } from '@memed/epiderme';
import { DropdownList, ItemListIndustries } from './ListIndustries.styles';
import { IIndustry } from './Industry.interface';

type ListIndustriesProps = {
  label: string;
  industries: IIndustry[];
  showResults: boolean;
  addIndustry: (param: IIndustry) => void;
}

export const ListIndustries = ({
  label, industries, showResults, addIndustry,
} : ListIndustriesProps) => {
  const hasIndustries = industries.length > 0;

  return (
    <DropdownList showResults={showResults}>
      { hasIndustries
        ? industries.map((industry: IIndustry) => (
          <ItemListIndustries onClick={() => addIndustry(industry)} key={industry.id}>
            <Text lineHeight="base" color="gray.700" fontSize="sm">{industry.name}</Text>
          </ItemListIndustries>
        ))
        : <Text align="center" color="gray.700" fontSize="sm" paddingTop={2}>{label}</Text>}
    </DropdownList>
  );
};
