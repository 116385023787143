/* eslint-disable react/no-children-prop */
import { Input as ChakraInput, Box, CloseButton } from '@chakra-ui/react';
import theme, { useInputValidation, InputBase } from '@memed/epiderme';
import { IInputBase } from '@memed/epiderme/lib/components/Forms/Inputs/InputBase/InputBase.interface';
import { useEffect, useState } from 'react';
import { EyeHidden } from '../../Icons/EyeHidden';
import { EyeShow } from '../../Icons/EyeShow';
import InputIconBase from '../InputIconBase';
import {
  errorMessages,
  hasMinLength,
  hasUppercase,
  hasSpecialCaracter,
} from './passwordUtils';

const InputPassword = ({ ...props }: IInputBase) => {
  const {
    showValidation,
    isValid,
    errorMessage,
    fieldValue,
    isFilled,
    iconColor,
    handleOnChange,
    handleOnBlur,
    setShowValidation,
    updateAndValidate,
  } = useInputValidation({
    props,
    customValidation: props.externalCustomValidation?.length
      ? props.externalCustomValidation
      : [hasUppercase, hasSpecialCaracter, hasMinLength],
    customErrorMessage: [errorMessages.UPPERCASE_REQUIRED, errorMessages.SPECIAL_CARACTER_REQUIRED, errorMessages.MIN_LENGTH],
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleInputType = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (props.value !== undefined && props.value !== null && props.value !== fieldValue) {
      updateAndValidate(props.value as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.isRequired]);

  useEffect(() => {
    if (props.showValidation !== showValidation) {
      setShowValidation(!!props.showValidation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showValidation]);

  return (
    <InputBase
      isInvalid={showValidation && !isValid}
      errorText={errorMessage}
      {...props}
    >
      <InputIconBase
        iconColor={iconColor}
        isLoading={props.isLoading}
        iconLeft={props.iconLeft}
        iconRight={(
          <Box
            as={CloseButton}
            size="md"
            role="button"
            disabled={props.isDisabled}
            _disabled={{
              pointerEvents: "none",
            }}
            onClick={handleInputType}
            aria-label={showPassword ? "show" : "hide"}
          >
            {showPassword 
              ? (<EyeShow color="neutral.600" boxSize={theme.sizes[6]} />) 
              : (<EyeHidden color="neutral.600" boxSize={theme.sizes[6]} />)
            }
          </Box>)}
      >
        <ChakraInput
          className={props.isSuccess ? "input-success" : ''}
          value={fieldValue}
          onChange={(evt) => handleOnChange(evt)}
          onBlur={(evt) => handleOnBlur(evt)}
          minLength={props.minLength}
          maxLength={props.maxLength}
          name={props.name}
          type={showPassword ? "text" : "password"}
          placeholder={props.placeholder}
          data-is-valid={isValid}
          data-is-filled={isFilled}
          data-error-message={errorMessage}
          data-set-show-validation={JSON.stringify(setShowValidation)} />
      </InputIconBase>
    </InputBase>
  );
};

export default InputPassword;
