import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { IIndustry } from '../components/ListIndustries/Industry.interface'
import { api } from '../services/api'
import { Manufacture } from '../services/types/manufacture'

export const useFetchIndustry = () => {
  
  const [industries, setIndustries] = useState<IIndustry[]>([])
  const [errorFetchIndustry, setErrorFetchIndustry] = useState(false)

  useEffect(() => {
    let isMoutend = true
    api.get('/industry')
      .then(({ data }: AxiosResponse<Manufacture[]>) => {
        if (isMoutend) {
          setIndustries([...data])
        }
      })
      .catch(e => {
        setErrorFetchIndustry(true)
        console.error('Industries not found')
      })
    return () => {
      isMoutend = false
    }
  }, [])

  return { industries, errorFetchIndustry }

}