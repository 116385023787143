import { Flex, Tab as ChakraTab } from '@chakra-ui/react'
import theme from '@memed/epiderme'
import styled from '@emotion/styled'

export const Tab = styled(ChakraTab)`

  border-bottom: 0;
  border-radius: ${theme.radii.lg};

  &:disabled {
    opacity: 1;
    color: ${theme.colors.neutral[400]};
  }

  &:focus {
    box-shadow: ${theme.shadows.outline};
    :active {
      background-color: ${theme.colors.primary[50]};
    }
  }

  &[aria-selected=true] {
    color: ${theme.colors.primary[700]};
    position: relative;
    &:after {
      content: '';
      width: 90%;
      height: .25rem;
      background-color: ${theme.colors.primary[700]};
      position: absolute;
      border-radius: .25rem .25rem 0 0;
      bottom: 0;
    }
  }

`

export const FlexRegister = styled(Flex)`

  background-color: ${theme.colors.whiteAlpha[900]};
  flex-direction: column;
  justify-content: center;
  padding: ${theme.space[6]};
  box-shadow: ${theme.shadows.lg};
  max-width: fit-content;
  min-height: auto;

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    margin-left: 25rem;
    border-radius: ${theme.radii.xl};
  }

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    max-width: 32rem;
    border-radius: ${theme.radii.xl};
  }

`