import theme, { Tooltip as TooltipEpiderme } from '@memed/epiderme'
import { Info } from '../Icons/Info'

export const Tooltip = ({ labelContent }: { labelContent : string }) => {
  return (
    <TooltipEpiderme
      placement="bottom-end"
      labelContent={labelContent}
    >
      <Info color="neutral.600" boxSize={theme.sizes[6]}  />
    </TooltipEpiderme>
  )
}