import { PropsWithChildren } from 'react'
import { Box } from '@memed/epiderme'
import bgAuth from '../../assets/bg-auth.png'

interface AppContainerProps extends PropsWithChildren {}

export const AppContainer = ({ children }: AppContainerProps) => (
  <Box
    width="100vw"
    minH="100vh"
    backgroundImage={bgAuth}
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
    backgroundPosition="center center"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </Box>
)